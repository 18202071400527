import React from "react"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import tick from "../../images/cyberSecurity/tick.png"
import * as styles from "./Benefit.module.scss"

const WhyChoose = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.hireCyber}>
      <Container>
        <div className={styles.hire}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={styles.hireImg2}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.secImages[0]?.alternativeText}
                decoding="async"
                loading="lazy"
              />
            )}
          </div>
        </div>
        <Row className="gap-30">
          <div>
            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img className={styles.tick} src={tick} alt={item?.title} />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default WhyChoose
