import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Services.module.scss"

const Services = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div></div>
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col xl={4} md={6} xs={12} key={i} className="gap-30">
              <div className={styles.card}>
                <img
                  src={e?.image1[0]?.localFile?.publicURL}
                  decoding="async"
                  loading="lazy"
                  alt={e?.image1[0]?.alternativeText}
                />
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className={`${styles.bannerBtn}`}>
          {strapiData?.buttons[0] && (
            <Link
              to={strapiData?.buttons[0]?.url}
              className={styles.btn_white2_border}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Services
