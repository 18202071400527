// extracted by mini-css-extract-plugin
export var SliderWidth = "Harness-module--SliderWidth--ea975";
export var arrowImg = "Harness-module--arrowImg--e0dd4";
export var card = "Harness-module--card--0d32d";
export var cardWrapper = "Harness-module--cardWrapper--f8e4a";
export var cards = "Harness-module--cards--e1495";
export var description = "Harness-module--description--a7c13";
export var heading = "Harness-module--heading--cd822";
export var headingPremium = "Harness-module--headingPremium--2a761";
export var premiumBg = "Harness-module--premiumBg--8c1a4";
export var premiumImg = "Harness-module--premiumImg--2cef2";
export var providingImg = "Harness-module--providingImg--072e2";
export var teamButton = "Harness-module--teamButton--a13b2";
export var teamWork = "Harness-module--teamWork--342f1";
export var trailBg = "Harness-module--trailBg--9a5b0";