import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./Industry.module.scss"

const Ready = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.ready}>
      <Container className={styles.pad}>
        <div className={styles.readyBg}>
          <Row>
            <Col lg={7}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div className={`${styles.bannerBtn}`}>
                {strapiData?.buttons[0] && (
                  <Link
                    to={strapiData?.buttons[0]?.url}
                    className={styles.btn_white2_border}
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </Col>
            <Col lg={5} md={12}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                  className={styles.bnrImg}
                />
              ) : (
                <img
                  src={strapiData?.secImages[0]?.localFile?.publicURL}
                  alt={strapiData?.title}
                  decoding="async"
                  loading="lazy"
                />
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Ready
