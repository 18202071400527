// extracted by mini-css-extract-plugin
export var Ideation = "Disposal-module--Ideation--9e497";
export var bannerBtn = "Disposal-module--bannerBtn--21a75";
export var btn_white1_border = "Disposal-module--btn_white1_border--e8193";
export var cards = "Disposal-module--cards--0a8d8";
export var cards2 = "Disposal-module--cards2--ce80d";
export var cardsData = "Disposal-module--cardsData--06386";
export var closeUp = "Disposal-module--closeUp--32d9c";
export var dec = "Disposal-module--dec--a27d9";
export var heading = "Disposal-module--heading--0b2eb";
export var leftText = "Disposal-module--leftText--a320d";
export var mobBg = "Disposal-module--mobBg--0967f";
export var number = "Disposal-module--number--3a666";
export var number2 = "Disposal-module--number2--c75af";
export var peakNumber = "Disposal-module--peakNumber--d5c16";
export var peakNumber2 = "Disposal-module--peakNumber2--11ccc";
export var research = "Disposal-module--research--7dc62";
export var rightText = "Disposal-module--rightText--b7b0d";
export var serial2 = "Disposal-module--serial2--6747f";
export var step = "Disposal-module--step--363ad";