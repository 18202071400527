// extracted by mini-css-extract-plugin
export var SliderWidth = "Boost-module--SliderWidth--68ca7";
export var arrowImg = "Boost-module--arrowImg--d58cf";
export var card = "Boost-module--card--94596";
export var cardWrapper = "Boost-module--cardWrapper--e0cae";
export var cards = "Boost-module--cards--01818";
export var description = "Boost-module--description--a4aa4";
export var heading = "Boost-module--heading--7039e";
export var headingPremium = "Boost-module--headingPremium--b3cb6";
export var premiumBg = "Boost-module--premiumBg--9b782";
export var premiumImg = "Boost-module--premiumImg--aebf4";
export var providingImg = "Boost-module--providingImg--6341c";
export var teamButton = "Boost-module--teamButton--e0b25";
export var teamWork = "Boost-module--teamWork--e3ef2";
export var trailBg = "Boost-module--trailBg--05135";