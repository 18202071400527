// extracted by mini-css-extract-plugin
export var Ready = "Ready-module--Ready--f0594";
export var bannerBtn = "Ready-module--bannerBtn--7f83e";
export var btn_white2_border = "Ready-module--btn_white2_border--75ddd";
export var card = "Ready-module--card--8d406";
export var cardWrapper = "Ready-module--cardWrapper--9fae3";
export var con = "Ready-module--con--3c35a";
export var description = "Ready-module--description--d28ee";
export var description2 = "Ready-module--description2--84d4f";
export var heading = "Ready-module--heading--22a86";
export var subservice = "Ready-module--subservice--6ac92";