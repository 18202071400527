import React from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./tabbing.scss"
import * as styles from "./Tools2.module.scss"

const Establishing = ({ strapiData }) => {
  return (
    <div className="tech">
      <div className={styles.globalIndus}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData[0]?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData[0]?.subTitle,
            }}
          />
          <div className={styles.cardBg}></div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${strapiData[0]?.subTitle2}`}
          >
            <div className={styles.cards}>
              <div className={styles.cardsContent}>
                <div>
                  <Nav variant="pills" className={`commerceTab`}>
                    {strapiData &&
                      strapiData?.map((e, i) => (
                        <Nav.Item key={i}>
                          <Nav.Link eventKey={`${e?.subTitle2}`}>
                            <div className={styles.tabData}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle2,
                                }}
                              />
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                  </Nav>
                </div>
                <Tab.Content className="contentBlock">
                  {strapiData?.map((e, i) => (
                    <Tab.Pane eventKey={`${e?.subTitle2}`} key={i}>
                      <div className={styles.techIcon}>
                        {e?.cards &&
                          e?.cards?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.image1[0]?.localFile?.publicURL}
                                    alt={el?.title}
                                  />
                                </div>
                                <h3>{el?.title}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default Establishing
