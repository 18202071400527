// extracted by mini-css-extract-plugin
export var btn = "Benefit-module--btn--c3a3f";
export var cards = "Benefit-module--cards--60a8f";
export var description = "Benefit-module--description--24589";
export var hire = "Benefit-module--hire--037db";
export var hireCyber = "Benefit-module--hireCyber--1ab56";
export var hireImg2 = "Benefit-module--hireImg2--85be8";
export var talent = "Benefit-module--talent--d8e87";
export var teamBtn = "Benefit-module--teamBtn--4633c";
export var text = "Benefit-module--text--0810a";
export var tick = "Benefit-module--tick--c22a4";