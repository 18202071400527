// extracted by mini-css-extract-plugin
export var bannerBtn = "Disposal2-module--bannerBtn--40f96";
export var btn_white1_border = "Disposal2-module--btn_white1_border--5a886";
export var card = "Disposal2-module--card--2a9d4";
export var cardWrapper = "Disposal2-module--cardWrapper--710f5";
export var choseImg = "Disposal2-module--choseImg--c34d0";
export var description = "Disposal2-module--description--b278a";
export var heading = "Disposal2-module--heading--f50ea";
export var ignite = "Disposal2-module--ignite--4f797";
export var ignite2 = "Disposal2-module--ignite2--6f22c";
export var number = "Disposal2-module--number--a9a25";
export var subservice = "Disposal2-module--subservice--1044f";
export var teamBtn = "Disposal2-module--teamBtn--43330";
export var title = "Disposal2-module--title--95b2c";
export var title2 = "Disposal2-module--title2--a5381";