import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import * as styles from "./Harness.module.scss"
import "./eCommercce.scss"

const Technologies = ({ strapiData }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 2 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current, current_1, current_2])

  const settings = {
    autoplay: true,
    dots: true,
    dotsClass: "eCommercce2",
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    speed: 200,
    beforeChange: (current, next) => setCurrent(next),
    arrows: false,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <div className={styles.teamWork}>
          <Container>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <Row className={`gap-30 ${styles.cardWrapper}`}>
              <Slider {...settings} className={styles.SliderWidth} ref={slide1}>
                {strapiData?.cards &&
                  strapiData?.cards?.map((e, i) => (
                    <Col xs={12} key={i}>
                      <div className={styles.card}>
                        <img
                          src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                          alt={e?.image1 && e?.image1[0]?.alternativeText}
                          decoding="async"
                          loading="lazy"
                        />
                        <h3>{e?.title}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: e?.subTitle,
                          }}
                        />
                      </div>
                    </Col>
                  ))}
              </Slider>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Technologies
