import React from "react"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./Disposal.module.scss"

const Disposal = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.Ideation}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.dec}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.cardsData}>
          <div className={styles.leftText}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i < 3 && (
                    <div className={styles.cards} key={i}>
                      <div className={styles.peakNumber2}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: e?.subTitle2,
                          }}
                          className={styles.serial2}
                        />
                        <div className={styles.research}>
                          <span>0{i + 1}-</span>
                          <h3>{e?.title}</h3>
                        </div>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
          <div className={styles.closeUp}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.title}
                decoding="async"
                loading="lazy"
              />
            )}
          </div>
          <div className={styles.rightText}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i > 2 && (
                    <div className={styles.cards2} key={i}>
                      <div className={styles.peakNumber}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: e?.subTitle2,
                          }}
                          className={styles.serial2}
                        />
                        <div className={styles.research}>
                          <span>0{i + 1}-</span>
                          <h3>{e?.title}</h3>
                        </div>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
        </div>
        <div className={`${styles.bannerBtn}`}>
          {strapiData?.buttons[0] && (
            <Link
              to={strapiData?.buttons[0]?.url}
              className={styles.btn_white1_border}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Disposal
