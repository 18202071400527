// extracted by mini-css-extract-plugin
export var cardBg = "Tools2-module--cardBg--4ef53";
export var cards = "Tools2-module--cards--28f17";
export var cardsContent = "Tools2-module--cardsContent--90593";
export var description = "Tools2-module--description--c263a";
export var globalIndus = "Tools2-module--globalIndus--eaf65";
export var heading = "Tools2-module--heading--bbf9a";
export var iconssCard = "Tools2-module--iconssCard--e9737";
export var navItem = "Tools2-module--nav-item--ee031";
export var navbarBlock = "Tools2-module--navbarBlock--0433c";
export var tabData = "Tools2-module--tabData--aeee7";
export var tech = "Tools2-module--tech--1ef00";
export var techIcon = "Tools2-module--techIcon--575c9";
export var techImg = "Tools2-module--techImg--de605";
export var technologyIcon = "Tools2-module--technologyIcon--89419";